import React from 'react';

import Layout from '../components/Layout';
import logo from '../assets/images/cellerMinifundi.png';

const IndexPage = () => (
  <Layout>
    <article id="cookies-aviso-legal">
      <header>
        <img className="logo" src={logo} alt="Celler del Minifundi" />
        <h3>Información sobre las cookies que utilizamos en este sitio web</h3>
      </header>
      <section className="wrapper">
        <div className="inner">
          <article>
            <h3>Política de cookies</h3>
            <p>
              Desde Celler Minifundi deseamos informarte que nuestra página web
              utiliza cookies para analizar la navegación de usuarios.
            </p>
          </article>
          <article>
            <h3>¿Qué son las cookies?</h3>
            <p>
              Las cookies son archivos que se pueden descargar en su equipo a
              través de las páginas web. Son herramientas que tienen un papel
              esencial para la prestación de numerosos servicios de la sociedad
              de la información. Entre otros, permiten a una página web
              almacenar y recuperar información sobre los hábitos de navegación
              de un usuario o de su equipo y, dependiendo de la información
              obtenida, se pueden utilizar para reconocer al usuario y mejorar
              el servicio ofrecido.
            </p>
          </article>
          <article>
            <h3>Cookies utilizadas en la web</h3>
            <p>
              La aplicación que utilizamos para obtener y analizar la
              información de la navegación es: Google Analytics:
              www.google.com/analytics/
            </p>
            <p>
              Esta aplicación ha sido desarrollada por Google que nos presta el
              servicio de análisis de la audiencia de nuestra página. Esta
              empresa puede utilizar estos datos para mejorar sus propios
              servicios y para ofrecer servicios a otras empresas. Puedes
              conocer esos otros usos desde los enlaces indicados.
            </p>
            <p>
              Esta herramienta no utiliza datos como el nombre o apellido de los
              usuarios ni la dirección postal desde donde se conectan. La
              información que se obtiene está relacionada, por ejemplo, con el
              número de páginas visitas, el idioma, red social en la que se
              publican nuestras noticias, la ciudad a la que está asignada la
              dirección IP desde la que acceden los usuarios, el número de
              usuarios que nos visitan, la frecuencia y reincidencia de las
              visitas, el tiempo de visita, el navegador que usan, el operador o
              tipo de terminal desde el que se realiza la visita.
            </p>
          </article>
          <article>
            <h3>Consentimiento</h3>
            <p>
              Al navegar y continuar en nuestro Sitio Web estará consintiendo el
              uso de las cookies antes enunciadas, en las condiciones contenidas
              en la presente Política de Cookies.
            </p>
            <p>
              Le informamos de que, dado que las cookies no son necesarias para
              el uso de nuestro Sitio Web, puede bloquearlas o deshabilitarlas a
              través de la configuración de su navegador. Si las bloquea o
              deshabilita podrá seguir usando nuestro Sitio Web, aunque el uso
              de algunos de sus servicios podrá ser limitado y por tanto su
              experiencia en nuestro Sitio Web menos satisfactoria.
            </p>
            <p>
              Si desea revocar su consentimiento informado respecto a nuestra
              política de cookies, deberá eliminarlas en su dispositivo a través
              de la configuración de sus navegadores de Internet. Estos son los
              enlaces de los distintos navegadores que informan sobre las
              cookies:
            </p>
            <p>
              Firefox:
              https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
            </p>
            <p>
              Chrome:
              http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
            </p>
            <p>
              Explorer:
              http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9
            </p>
            <p>Safari: http://support.apple.com/kb/ph5042</p>
            <p>Opera: http://help.opera.com/Windows/11.50/es-ES/cookies.html</p>
            <p>
              Le recomendamos revisar esta política cada vez que acceda a
              nuestro Sitio Web con el objetivo de estar adecuadamente informado
              sobre posibles cambios en la misma.
            </p>
          </article>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
